import React, {useCallback, useState} from 'react';
import Backdrop from "@mui/material/Backdrop";
import {Box, Fade, Modal, Typography} from "@mui/material";
import {bitrix, global, input} from "../translate";
import Api from "../Api";
import {useNavigate} from "react-router-dom";
const {REACT_APP_SERVER} = process.env;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: window.innerWidth > 600 ? 600 : window.innerWidth,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    height: 'fit-content',
    overflow: 'hidden'
};
const REDIRECT_URI = REACT_APP_SERVER === 'ATS'?'https://account.ats.am':'https://ucom-acc.ats.am';

function BitrixEnter({open, setOpen}) {
    const [domain, setDomain] = useState('')
    const navigate = useNavigate()
    const handleSubmit = useCallback(async (ev) => {
        ev.preventDefault()
        try {
            await Api.bitrix_domain(domain)
            console.log(REDIRECT_URI,REACT_APP_SERVER)
            window.location = `https://${domain}/oauth/authorize?client_id=${REACT_APP_SERVER !== "ATS"?"app.66b23e138b7402.65430148":"app.66d027af0b5fe7.76960056"}&response_type=code&redirect_uri=${REDIRECT_URI}`
        } catch (e) {

        }
    }, [domain,REDIRECT_URI,REACT_APP_SERVER])
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={() => {
                setOpen(false)
            }}
            closeAfterTransition
            slots={{backdrop: Backdrop}}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={open}>
                <Box sx={style}>

                    <form onSubmit={handleSubmit} className="d-flex flex-column justify-content-between">
                        <div className="d-flex justify-content-between">
                            <h3>{bitrix.header[+localStorage.getItem('atsLang')  || 1]}</h3>
                            <span style={{cursor: 'pointer'}} onClick={() => {
                                setOpen(false)
                                navigate('/crm_integration')
                            }}>
                            x
                            </span>
                        </div>
                        <p className="crm_title"><span
                            className="number">1. </span><span>{REACT_APP_SERVER === 'ATS'?bitrix.titleA[+localStorage.getItem('atsLang')  || 1]:bitrix.title[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="crm_paragraph"><span>{bitrix.paragraph_p1[+localStorage.getItem('atsLang')  || 1]}
                            <a href={bitrix.link.link} target='_blank'>{REACT_APP_SERVER === 'ATS'?bitrix.link.textA[+localStorage.getItem('atsLang')  || 1]:bitrix.link.text[+localStorage.getItem('atsLang')  || 1]}</a>{bitrix.paragraph_p2[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="crm_title"><span
                            className="number">2. </span><span>{bitrix.title2[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                        <p className="crm_paragraph"><span>{bitrix.paragraph2[+localStorage.getItem('atsLang')  || 1]}</span>
                        </p>
                    </form>
                    <div id="transition-modal-description sip_content" className="test_mode">
                        <label className="count" style={{width: '93%',marginBottom:30}}>
                            <input value={domain} onChange={(ev) => setDomain(ev.target.value)}/>
                        </label>
                        <div className="d-flex justify-content-center">
                            <button style={{padding: '6px 17px'}} className="addBtn" onClick={handleSubmit}>
                                {global.edit[+localStorage.getItem('atsLang')  || 1]}
                            </button>
                        </div>
                    </div>
                </Box>
            </Fade>
        </Modal>
    );
}

export default BitrixEnter;
