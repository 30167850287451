import React, {useCallback, useEffect, useState} from 'react';
import Wrapper from "../components/Wrapper";
import BitrixEnter from "../components/BitrixEnter";
import {useSelector} from "react-redux";
import Api from "../Api";
import {useLocation, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import axios from "axios";
import qs from "query-string";
import zoro from '../assets/img/crm-logo.avif'
import {toast} from "react-toastify";
const {REACT_APP_SERVER} = process.env;
const client_id = REACT_APP_SERVER === 'ATS' ? '1000.OFHJBBAF54EL01AM5NUJXSTSQR78KL':'1000.ICNSLEJ412ROAB4XCH4XAOFWIMGF6D';
const URI = REACT_APP_SERVER === 'ATS'?'https://account.ats.am':'https://ucom-acc.ats.am';

const redirect_uri = `${URI}/crm_integration`;
function CrmIntegration(props) {
    const [bitrix,setBitrix] = useState(false)
    const [isInstalled,setIsInstalled] = useState(false)
    const [isInstalledZoho,setIsInstalledZoho] = useState(false)
    const userData = useSelector((state) => state.users.userData)
    const navigate = useNavigate()
    const location = useLocation()
    const query = qs.parse(location.search, {arrayFormat: 'comma'});
    const [crm, setCrm] = useState([])
    useEffect(() => {
        setIsInstalled(userData.bitrix?.member)
        setIsInstalledZoho(userData.zoho?.member_id)
    },[userData])
    const handleZoho = useCallback(async () => {
        window.location.href = `https://accounts.zoho.com/oauth/v2/auth?response_type=code&client_id=${client_id}&scope=ZohoCRM.modules.ALL,ZohoCRM.users.ALL,ZohoCRM.telephony.READ,ZohoCRM.telephony.WRITE,PhoneBridge.call.log,PhoneBridge.zohoone.search&redirect_uri=${redirect_uri}&access_type=offline&prompt=consent`
    }, [client_id,redirect_uri])
    const handleDelete = useCallback(async (type) => {
        try {
            if(type === 'bitrix'){
                await Api.bitrix_delete()
                setIsInstalled(false)
            }else{
                await Api.zohoDelete()
                setIsInstalledZoho(false)
            }
        }catch (e) {
            console.log(e)
        }
    }, [])


    useEffect(() => {
        if(location.hash === '#application_not_found'){
            setBitrix(true)
        }
    }, [location.hash])
    useEffect(() => {
        (async () => {
            try{
                if(query.code){
                    console.log(query)
                    const parsedUrl = new URL(query['accounts-server']);
                    const hostname = parsedUrl.hostname;
                    const region = hostname.split('.').pop();
                    await Api.zohoCode(query.code,region)
                    setIsInstalledZoho(true)
                    navigate('/crm_integration')
                }
            }catch (e) {
                toast.error(e.response.data.message)
            }
        })()
    }, [location.search])
    useEffect(() => {
        (async () => {
            const {data} = await axios.get(`https://ats.am/api/crm_integration?lang=${localStorage.getItem('atsLang')}`)
            setCrm(data.crm)
        })()
    },[])
    return (
        <Wrapper>
            <Helmet>
                <title>CRM integration</title>
            </Helmet>

            <div className="d-flex flex-wrap">
                {crm?.map(c => {
                    if(c.name){
                        return (<div key={c.id} className="crmBlock" style={{border: "1px solid rgb(204, 204, 204)", padding: "10px"}}>
                                <div>
                                    <div className=" blog-item-img d-flex flex-row align-items-center justify-content-center" style={{height: 140}}>
                                        <img
                                            src={"https://ats.am"+c.image}
                                            alt="" className="img-responsive" style={{width: "75%", height: "75%", objectFit: "contain"}}/>
                                    </div>
                                </div>
                                <div>

                                    <div className="blog-item-content"><h4 style={{marginBottom:25}}>{c.crm_integration_translate[0].title}</h4>
                                        <p style={{marginBottom:25}}>{c.crm_integration_translate[0].desc}</p>
                                        {!isInstalled?<div style={{borderRadius: 5}} className="addBtnBig" onClick={() => setBitrix(!bitrix)}>
                                            Add
                                        </div>:<div className="d-flex justify-content-between">
                                            <div style={{borderRadius: 5}} className="deleteBtn addBtnBig" onClick={() => handleDelete('bitrix')}>
                                                Delete
                                            </div>
                                            <div style={{borderRadius: 5}} className="addBtnBig" onClick={()=> navigate(`/crm_integration/${c.name}`)}>
                                                View
                                            </div>
                                        </div>}
                                    </div>


                                </div>
                            </div>
                        )
                    }
                })}
                <div className="crmBlock" style={{border: "1px solid rgb(204, 204, 204)", padding: "10px"}}>
                    <div>
                        <div className=" blog-item-img d-flex flex-row align-items-center justify-content-center" style={{height: 140}}>
                            <img
                                src={zoro}
                                alt="" className="img-responsive" style={{width: "75%", height: "75%", objectFit: "contain"}}/>
                        </div>
                    </div>
                    <div>

                        <div className="blog-item-content"><h4 style={{marginBottom:25}}>ZOHO CRM</h4>
                            <p style={{marginBottom:25}}>Zoho CRM is an online Sales CRM software to help manage your customer operations in one CRM platform. Trusted by over 100+ million users worldwide!</p>
                            {!isInstalledZoho?<div style={{borderRadius: 5}} className="addBtnBig" onClick={handleZoho}>
                                Add
                            </div>:<div className="d-flex justify-content-between">
                                <div style={{borderRadius: 5}} className="deleteBtn addBtnBig" onClick={() => handleDelete('zoho')}>
                                    Delete
                                </div>
                                <div style={{borderRadius: 5}} className="addBtnBig" onClick={()=> navigate(`/crm_integration/zoho`)}>
                                    View
                                </div>
                            </div>}
                        </div>


                    </div>
                </div>
                <BitrixEnter open={bitrix} setOpen={setBitrix} />
            </div>
        </Wrapper>
    );
}

export default CrmIntegration;
