import React, {useEffect} from 'react';
import {BrowserRouter,Routes,Route} from "react-router-dom";
import Home from "./pages/Home";
import History from "./pages/History";
import SipNumber from "./pages/SipNumber";
import Included from "./pages/Included";
import ShortNumber from "./pages/ShortNumber";
import Ivr from "./pages/Ivr";
import DidNumbers from "./pages/DidNumbers";
import SipProvider from "./pages/SipProvider";
import Forward from "./pages/Forward";
import CallRout from "./pages/CallRout";
import AutoCall from "./pages/AutoCall";
import Files from "./pages/Files";
import Blocked from "./pages/Blocked";
import Ip from "./pages/Ip";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import AutoCallEdit from "./pages/AutoCallEdit";
import Profile from "./pages/Profile";
import UrsLogin from "./pages/UrsLogin";
import Urs from "./pages/Urs";
import Confirm from "./pages/Confirm";
import ShortFunction from "./pages/ShortFunction";
import ClGroup from "./pages/ClGroup";
import Register from "./pages/Register";
import BlockedIp from "./pages/BlockedIp";
import Online from "./pages/Online";
import CrmIntegration from "./pages/CrmIntegration";
import Bitrix24 from "./pages/Bitrix24";
import UrsOnline from "./pages/UrsOnline";
import UrsUsed from "./pages/UrsUsed";
import UrsReports from "./pages/UrsReports";
import Logs from "./pages/Logs";
import Zoho from "./pages/Zoho";
const {REACT_APP_SERVER} = process.env;
let background = REACT_APP_SERVER === 'ATS'?'#0973b9':'#7ebc0a'
let color = REACT_APP_SERVER === 'ATS'?'#162585':'#5e8516'

function App(props) {
    useEffect(() => {
        document.documentElement.style.setProperty('--background', background);
        document.documentElement.style.setProperty('--color', color);

    }, [color,background,REACT_APP_SERVER])
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/sip_number" element={<SipNumber/>} />
                <Route path="/crm_integration" element={<CrmIntegration/>} />
                <Route path="/crm_integration/bitrix" element={<Bitrix24/>} />
                <Route path="/crm_integration/zoho" element={<Zoho/>} />
                {/*{REACT_APP_SERVER==='V-office'?<Route path="/crm_integration" element={<CrmIntegration/>} /> :null}*/}
                {/*{REACT_APP_SERVER==='V-office'?<Route path="/crm_integration/:crm" element={<Bitrix24/>} />:null}*/}
                <Route path="/online" element={<Online/>} />
                <Route path="/urs_online" element={<UrsOnline/>} />
                <Route path="/urs_used" element={<UrsUsed />} />
                <Route path="/registration" element={<Register/>} />
                <Route path="/clgroup" element={<ClGroup/>} />
                <Route path="/history" element={<History/>} />
                <Route path="/graphic" element={<History/>} />
                <Route path="/included" element={<Included/>} />
                <Route path="/short_numbers" element={<ShortNumber/>} />
                <Route path="/short_function" element={<ShortFunction/>} />
                <Route path="/ivr" element={<Ivr/>} />
                <Route path="/did_numbers" element={<DidNumbers/>} />
                <Route path="/sip_provider" element={<SipProvider/>} />
                <Route path="/forward" element={<Forward/>} />
                <Route path="/rout_phone_number" element={<CallRout/>} />
                <Route path="/auto_call" element={<AutoCall/>} />
                <Route path="/auto_call/:id" element={<AutoCallEdit/>} />
                <Route path="/files" element={<Files/>} />
                <Route path="/blocked" element={<Blocked/>} />
                <Route path="/blocked_ip" element={<BlockedIp/>} />
                <Route path="/ip" element={<Ip/>} />
                <Route path="/login" element={<Login/>} />
                <Route path="/admin_login" element={<UrsLogin/>} />
                <Route path="/urs" element={<Urs/>} />
                <Route path="/confirm/:type/:phone" element={<Confirm/>} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/report" element={<UrsReports />} />
                <Route path="/logs" element={<Logs />} />
                <Route path="/not-found" element={<NotFound/>} />
                <Route path="/*" element={<NotFound/>} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
